/* ##########################PROGRESS BUTTON */
.pb-container {
  display: inline-block;
  text-align: center;
  width: 100%;
  margin: 5px 7px;
}
.pb-container .pb-button {
  border-radius: 6px;
  color: currentColor;
  cursor: pointer;
  padding: 0.3em 0.3em;
  color: #ffffff;
  background-color: #022b51;
  text-decoration: none;
  text-align: center;
  height: 36px;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  transition: background-color 0.3s, width 0.3s, border-width 0.3s, border-color 0.3s, border-radius 0.3s;
}

.pb-container .pb-button:hover {
  background-color: #054a8a;
}
.pb-container .pb-button span {
  display: inherit;
  transition: opacity 0.3s 0.1s;
  font-size: 12px;
}
.pb-container .pb-button svg {
  height: 54px;
  width: 54px;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.pb-container .pb-button svg path {
  opacity: 0;
  fill: none;
}
.pb-container .pb-button svg.pb-progress-circle {
  animation: spin 0.9s infinite cubic-bezier(0.085, 0.260, 0.935, 0.710);
}
.pb-container .pb-button svg.pb-progress-circle path {
  stroke: currentColor;
  stroke-width: 5;
}
.pb-container .pb-button svg.pb-checkmark path,
.pb-container .pb-button svg.pb-cross path {
  stroke: #fff;
  stroke-linecap: round;
  stroke-width: 4;
}
.pb-container.disabled .pb-button {
  cursor: not-allowed;
}
.pb-container.loading .pb-button {
  width: 54px;
  border-width: 6.5px;
  border-color: #022b51;
  cursor: wait;
  background-color: transparent;
  padding: 0;
}
.pb-container.loading .pb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.pb-container.loading .pb-button .pb-progress-circle > path {
  transition: opacity 0.15s 0.3s;
  opacity: 1;
}
.pb-container.success .pb-button {
  border-color:  #198754;
  background-color:  #198754;
}
.pb-container.success .pb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.pb-container.success .pb-button .pb-checkmark > path {
  opacity: 1;
}
.pb-container.error .pb-button {
  border-color: #ED5565;
  background-color: #ED5565;
}
.pb-container.error .pb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.pb-container.error .pb-button .pb-cross > path {
  opacity: 1;
}
@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
    transform-origin: center center;
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
    transform-origin: center center;
  }
}

/* ##########################PROGRESS BUTTON */


/* PAYMENT HISTORY ACCORDION */
.tab input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}


/* Accordion styles */
.tabs {
  border-radius: 8px;
  overflow: hidden;
  /* box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5); */
  width: 95%;
  margin: 10px auto;
}

/* Small screens */
@media only screen and (max-width: 600px) {
  .tabs {
    width: 95%;
  }
  }

.tab {
  width: 100%;
  color: white;
  overflow: hidden;
}
.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: #022b51;
  font-weight: bold;
  cursor: pointer;
  /* Icon */
}
.tab-label:hover {
  background: #03427c;
}
.tab-label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all .35s;
}
.tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all .35s;
}
.tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}
.tab-close:hover {
  background: #03427c;
}

input:checked + .tab-label {
  background: #1a252f;
}
input:checked + .tab-label::after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}


.customInputs input:focus, textarea:focus {
  outline: 0 !important;
  border-bottom-color: #ffab00 !important;
}
